import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import '../styles/app.scss';
import SEO from '../components/seo';

const SpecialEvents = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section smallestwdt" id="bookparty">
                <div className="container">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.se_title,
                        }}
                    />
                    <div className="bookawrap">
                        <a
                            href={`tel:${post.acf.se_call_us_number}`}
                            className="wrtsbtn yellowbtn fullbtn"
                        >
                            CALL US TODAY: {post.acf.se_call_us_number}
                        </a>
                    </div>
                    <img src={LineFull} alt="linefull" />
                </div>
            </section>
            <section className="page-section notoppaddsec">
                <div className="container flexwrap bdaytxtadjust">
                    <div className="whylistlft flexbox todwn">
                        <img
                            src={
                                post.acf.se_book_image.localFile.childImageSharp
                                    .fluid.srcWebp
                            }
                            alt="special1"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.se_book_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.se_book_content,
                            }}
                        />
                    </div>
                </div>
            </section>
            {post.acf.special_events_list.map((box, i) => {
                console.log(i % 2);
                if (i % 2 === 0 || i === 0) {
                    return (
                        <section
                            className="whywelist page-section bg-primary text-white specialtripsbtn"
                            key={i}
                        >
                            <div className="container flexwrap bdaytxtadjust">
                                <div className="whylistlft flexbox">
                                    <h2
                                        className="yellowtxt"
                                        dangerouslySetInnerHTML={{
                                            __html: box.se_box_title,
                                        }}
                                    />
                                    <h4
                                        dangerouslySetInnerHTML={{
                                            __html: box.se_box_content,
                                        }}
                                    />
                                    <a
                                        href={box.se_button_url}
                                        className="wrtsbtn yellowbtn inlinebtn"
                                        dangerouslySetInnerHTML={{
                                            __html: box.se_box_button_text,
                                        }}
                                    />
                                </div>

                                <div className="whylistrgt flexbox">
                                    <div className="equipstarimgrgt">
                                        <img
                                            className="whiteborderimg"
                                            src={
                                                box.se_box_image.localFile
                                                    .childImageSharp.fluid
                                                    .srcWebp
                                            }
                                            alt="special5"
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    );
                }
                if (i === 3) {
                    return (
                        <section
                            className="whywelist page-section bg-secondary text-white specialtripsbtn"
                            key={i}
                        >
                            <div className="container flexwrap bdaytxtadjust">
                                <div className="whylistrgt flexbox todwn">
                                    <img
                                        className="whiteborderimg"
                                        src={
                                            box.se_box_image.localFile
                                                .childImageSharp.fluid.src
                                        }
                                        alt="special 2"
                                    />
                                </div>

                                <div className="whywelist flexbox toup">
                                    <h2
                                        className="yellowtxt"
                                        dangerouslySetInnerHTML={{
                                            __html: box.se_box_title,
                                        }}
                                    />
                                    <h4
                                        dangerouslySetInnerHTML={{
                                            __html: box.se_box_content,
                                        }}
                                    />
                                    <a
                                        href={box.se_button_url}
                                        className="wrtsbtn yellowbtn inlinebtn"
                                        dangerouslySetInnerHTML={{
                                            __html: box.se_box_button_text,
                                        }}
                                    />
                                </div>
                            </div>
                        </section>
                    );
                }
                return (
                    <section className="page-section specialtripsbtn" key={i}>
                        <div className="container flexwrap bdaytxtadjust">
                            <div className="whylistrgt flexbox todwn">
                                <img
                                    className="whiteborderimg"
                                    src={
                                        box.se_box_image.localFile
                                            .childImageSharp.fluid.src
                                    }
                                    alt="special 2"
                                />
                            </div>

                            <div className="whywelist flexbox toup">
                                <h2
                                    className="bluetxt"
                                    dangerouslySetInnerHTML={{
                                        __html: box.se_box_title,
                                    }}
                                />
                                <h4
                                    dangerouslySetInnerHTML={{
                                        __html: box.se_box_content,
                                    }}
                                />
                                <a
                                    href={box.se_button_url}
                                    className="wrtsbtn yellowbtn inlinebtn"
                                    dangerouslySetInnerHTML={{
                                        __html: box.se_box_button_text,
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                );
            })}
            <Footer />
        </>
    );
};

export default SpecialEvents;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
            acf {
                se_book_content
                se_book_title
                se_call_us_number
                se_title
                se_book_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                special_events_list {
                    se_box_button_text
                    se_box_content
                    se_box_title
                    se_button_url
                    se_box_image {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    src
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
